.header {
  height: 8em;
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.curve.top {
  top: 0;
  left: 0;
}

.curve img {
  object-fit: contain;
}
@media (max-width: 600px) {
  .header {
    height: 6em;
  }

  .curve {
    display: none;
  }
}
