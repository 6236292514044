.scroll-top {
  position: fixed;
  bottom: 2em;
  right: 4em;
  z-index: 99;
  background-color: transparent;
}

@media (max-width: 900px) {
  .scroll-top {
    display: none;
  }
}
