.about {
  text-align: center;
  position: relative;
  min-height: 70vh;
  flex-direction: column;
  justify-content: center;
}

.about__name {
  color: var(--clr-primary);
}

.about__role {
  margin-top: 1.2em;
}

.about .photo {
  margin-bottom: 1rem;
  border-radius: 50%;
  overflow: hidden;
}

.about__desc {
  font-size: 1rem;
  max-width: 600px;
}

.about__desc,
.about__contact {
  margin-top: 2.4em;
}

.about__contact {
  display: flex;
  gap: 1.5rem;
}

.about .btn--outline {
  margin-right: 1em;
}

@media (max-width: 600px) {
  .about {
    text-align: unset;
    margin-top: 3em;
    min-height: unset;
  }
  .app .about {
    align-items: flex-start;
    margin-top: 2em;
  }
}
