.app {
  font-family: 'Poppins', sans-serif;
  line-height: 1.5;
  color: var(--clr-fg);
  background-color: var(--clr-bg);
  position: relative;
}

.light {
  transition: .5s;
  --clr-bg: #fcfcfc;
  --clr-bg-alt: #fff;
  --clr-fg: #555;
  --clr-fg-alt: #444;
  --clr-primary: #7508B9;
  --clr-primary-badges: rgba(117, 8, 185, .1);
  --clr-primary-badges-border: rgba(117, 8, 185, .3);
  --clr-border: rgba(68, 68, 68, .1);
  --shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  --clr-curve: opacity(.6);
}

.dark {
  transition: .5s;
  --clr-bg: #23283e;
  --clr-bg-alt: #2a2f4c;
  --clr-fg: #bdbddd;
  --clr-fg-alt: #cdcdff;
  --clr-primary: #01C564;
  --clr-primary-badges: rgba(1, 197, 100, .1);
  --clr-primary-badges-border: rgba(1, 197, 100, .3);
  --clr-border: rgba(205, 205, 255, .1);
  --shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  --clr-curve: invert(1) opacity(.4);
}

main {
  max-width: 1100px;
  width: 95%;
  margin: 0 auto;
}

.curve {
  overflow: hidden;
  position: absolute;
  z-index: 0;
  filter: var(--clr-curve);
}

.section {
  margin-top: 7em;
}

.section__title {
  text-align: center;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.center {
  display: flex;
  align-items: center;
}

.link {
  color: var(--clr-primary);
  padding: 0 0 0.3em 0;
  position: relative;
}

.link:hover {
  color: var(--clr-primary);
}

.link::before {
  content: '';
  display: inline;
  width: 0%;
  height: 0.2em;
  position: absolute;
  bottom: 0;
  background-color: var(--clr-primary);
  transition: width 0.2s ease-in;
}

.link:hover::before,
.link:focus::before {
  width: 100%;
}

.link--nav {
  color: var(--clr-fg);
  text-transform: lowercase;
  font-weight: 500;
}

.link--icon {
  color: var(--clr-fg);
}

.btn {
  display: block;
  cursor: pointer;
  padding: 0.8em 1.4em;
  font-weight: 500;
  font-size: 0.9rem;
  background-color: inherit;
  text-transform: lowercase;
  transition: transform 0.2s ease-in-out;
}

.btn--outline {
  display: flex;
  align-items: center;
  gap: .45rem;
  color: var(--clr-primary);
  border: 2px solid var(--clr-primary);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.btn.disabled {
  opacity: .5;
  cursor: default;
  border-color: var(--clr-fg-alt);
  color: var(--clr-fg-alt);
}

.btn.mini {
  padding: .35rem .5rem;
}

.btn--outline:hover:not(.disabled),
.btn--outline:focus:not(.disabled) {
  color: var(--clr-bg);
}

.btn--outline:before {
  content: '';
  position: absolute;
  background-color: var(--clr-primary);
  right: 100%;
  bottom: 0;
  left: 0;
  top: 0;
  z-index: -1;
  transition: right 0.2s ease-in-out;
}

.btn--outline:not(.disabled):hover:before,
.btn--outline:not(.disabled):focus:before {
  right: 0;
}

.btn--plain {
  text-transform: initial;
  background-color: var(--clr-bg-alt);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  border: 0;
}

.btn--plain:hover {
  transform: translateY(-4px);
}

.btn--icon {
  padding: 0;
}

.btn--icon:hover,
.btn--icon:focus {
  color: var(--clr-primary);
}

.btn--icon:active {
  transform: translateY(-5px);
}

p {
  opacity: .8;
}

@media (max-width: 600px) {
  .section {
    margin-top: 4em;
  }
}
