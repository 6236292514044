.project {
  padding: 2em;
  margin: 0 auto;
  text-align: center;
  box-shadow: var(--shadow);
  transition: transform 0.2s linear;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.project:hover {
  transform: translateY(-7px);
}

.project__description {
  margin-top: 1em;
  flex: 1;
  border-bottom: 1px solid var(--clr-border);
  padding-bottom: 1rem;
}

.project__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.2em 0;
}

.project__stack-item {
  margin: 0.5em;
  font-weight: 500;
  font-size: 12px;
  color: var(--clr-fg-alt);
  background: var(--clr-primary-badges);
  border: 1px solid var(--clr-primary-badges-border);
  border-radius: 1rem;
  padding: .05rem .5rem;
}

.project .action {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.project .action .link--icon {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.project .action small {
  font-size: 14px;
}

.project .ongoing {
  display: flex;
  align-items: center;
  gap: .45rem;
}

.project .reason {
  color: var(--clr-primary);
  display: block;
  font-size: 10px;
}